@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
    background: #EFF6F8;
}
@layer base {
  html {
    font-family: 'Roboto', sans-serif !important;
  }
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}
.login-bg{
    background: linear-gradient(21deg, rgba(5,128,250,1) 4%, rgba(250,253,255,1) 100%) !important;
}
.bg-polygon{
    clip-path: polygon(8% 0, 99% 0%, 92% 100%, 0% 100%);
    padding: 0 10px;
    color: white;
    background: #D9232D;
    font-weight: bold;
}

.header-data{
  .fgff{
    &.odd-child {
      background: #fff !important;
    }

    &.even-child {
      background: red !important;
    }
  }
}
.skeleton-container {
  // background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.skeleton-content {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 3s infinite;
}
.user-img{
  border-radius: 100% !important;
}
@keyframes loading {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}